import {useEffect} from "react";
import useScroll from "../../hooks/view/useScroll";
import {EVT_ADDED_MESSAGE} from "../../hooks/domain/useMessage";
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

function getCurrentTime() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedHours = hours > 12 ? hours - 12 : hours;
    const period = hours >= 12 ? '오후' : '오전';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${period} ${formattedHours}:${formattedMinutes}`;
}

export default function MessageBody(props) {
    const {messages, isLoading, messageEvent} = props
    const {
        messagesEndRef,
        scrollToBottom
    } = useScroll()

    useEffect(() => {
        if (messageEvent === EVT_ADDED_MESSAGE) {
            scrollToBottom()
        }
    }, [messageEvent]);


    return (
        <>
            {messages.map((message, index) => (
                <div
                    className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'}`}
                    key={index}>
                    {message.sender === 'user' ?
                        <div className='user-say-text'>{message.text}</div>
                        : <div className='user-say-text'><ReactMarkdown remarkPlugins={[remarkBreaks]}>{message.text}</ReactMarkdown></div>
                    }
                    <div className="msg-time">{getCurrentTime()}</div>
                </div>
            ))}
            <div ref={messagesEndRef}/>
            {isLoading && <div className="loading-indicator"></div>}
        </>
    )
}