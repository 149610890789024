import {useEffect, useState} from "react";
import {adminApi} from "../../aaxios";


async function createChat(data) {
    /*
    * class CreateChatReq(BaseModel):
    session_id: str
    message: str
    query_type: ChatMessageQueryTypeEnum -> {text,pdf}
    *
    * */
    const {
        sessionId,
        message,
        queryType
    } = data

    try {
        const res = await adminApi.post('/chat/GUEST', {
            'session_id': sessionId,
            'message': message,
            "query_type": queryType
        });
        return res.data['data']
    } catch (error) {
        console.error('Error:', error);
    }
}

async function addMessageToChat(data) {
    const {
        chatId,
        message,
        queryType
    } = data

    try {
        const res = await adminApi.post(`/chat/GUEST/${chatId}/message`, {
            'message': message,
            "query_type": queryType
        });
        return res.data['data']
    } catch (error) {
        console.error('Error:', error);
    }
}


const EVT_INIT_MESSAGE = "EVT_INIT_MESSAGE"
const EVT_ADD_MESSAGE = "EVT_ADD_MESSAGE"
export const EVT_ADDED_MESSAGE = "EVT_ADDED_MESSAGE"

export default function useMessage({sessionId}) {
    const [messages, setMessages] = useState([])
    const [isMessageLoading, setIsMessageLoading] = useState(false)
    const [answerNum, setAnswerNum] = useState(0);
    const [messageEvent, setMessageEvent] = useState(EVT_INIT_MESSAGE)
    const [chatId, setChatId] = useState(null)
    const [viewMode, setViewMode] = useState("text")

    function addMessage(message) {
        setMessages((prevMessages) => [...prevMessages, {...message, "query_type": viewMode}])
        setMessageEvent(EVT_ADD_MESSAGE)
        return true
    }


    useEffect(() => {
        if (messageEvent !== EVT_ADD_MESSAGE) return

        setIsMessageLoading(true)

        function sendMessage(viewMessage, data) {
            setMessages(prevMessages => [...prevMessages, {
                text: viewMessage,
                sender: 'bot',
                "query_type": data['query_type'],
                "resData": data
            }]);
            setAnswerNum(prevAnswerNum => prevAnswerNum + 1)
            setIsMessageLoading(false)
            setMessageEvent(EVT_ADDED_MESSAGE)
        }

        function sendMessageCallBack(data) {
            console.log(data);
            setChatId(data['chat_id'])
            sendMessage(data['answer']['message'], data)
        }

        const req = {
            sessionId,
            chatId,
            message: messages[messages.length - 1].text,
            queryType: viewMode
        }
        if (!chatId) {
            createChat(req).then(sendMessageCallBack)
            return;
        }
        addMessageToChat(req).then(sendMessageCallBack)
    }, [messageEvent]);

    function onChangeViewMode(nextViewMode) {

        setViewMode(nextViewMode)
    }

    return {
        isMessageLoading,
        messages,
        addMessage,
        messageEvent,
        viewMode,
        onChangeViewMode
    }

}